// @ts-nocheck

import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { debounce, get, has, set } from 'lodash';

import { saveToS3 } from './saveJson';

import useActiveUser from './useActiveUser';
import useYDocV2 from './useYDocV2';

import * as Y from 'yjs';
import useUserOrigin from './useUserOrigin';
import useActiveOrganisation from './useActiveOrganisation';

let decr = false;

async function hashString(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

let obs = 0;

const stopOnMetadataChange = true;
/*
const d = new Y.Doc();
const m = d.getMap('test');
m.set('test', { id: 123123, text: 'hello' });
const m2 = d.getMap('test');
console.log(m2.get('test'));
*/

const timers = {};
const documentCache = {};

const yDocListeners = {};

let prevMetadata, prevMetadataSet;

const useSyncedDocument = ({
  defaultValue,
  type = 'document',
  format,
  docId,
}: {
  defaultValue: any;
  type: string;
  docId: string;
}): [any, React.Dispatch<React.SetStateAction<any>>] => {
  const [value, setValue] = useState<any>(defaultValue);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [updated, setUpdated] = useState<boolean>(false);

  const userOrigin = useUserOrigin();

  const [activeOrganisation] = useActiveOrganisation();

  const [activeOrganisationId, setActiveOrganisationId] = useState(
    activeOrganisation.organizationId
  );

  useEffect(() => {
    setActiveOrganisationId(activeOrganisation.organizationId);
  }, [activeOrganisation]);

  const organizationId = activeOrganisationId;

  // console.log("using organisation id "+organizationId)

  const key = `${type}/${docId}`;
  let yMap;

  const { yDoc, loaded: yDocLoaded } = useYDocV2({
    organizationId,
    docId,
    onLoaded: () => {
      setLoaded(true);

      // const yMap = yDoc.getMap(type);
      const value = yMap.get(type);

      setValue(value);
    },
  });

  yMap = yDoc.getMap(type);

  if (yDocLoaded != loaded) {
    setLoaded(yDocLoaded);

    if (yDocLoaded) {
      // const yMap = yDoc.getMap(type);
      setValue(yMap.get(type));
    }
  }

  const store = useCallback(async () => {
    // const yMap = yDoc.getMap(type);
    const value = yMap.get(type);

    if (!value) return;

    const hash = await hashString(JSON.stringify(value));

    if (documentCache[key] !== hash) {
      documentCache[key] = hash;
      console.log('storing value', value, docId, type);
      saveToS3(value, `${docId}/${type}`).then(() => {
        // console.log('Saved dashboard to S3');
      });
    }
  }, [yDocLoaded]);

  const stateUpdate = useCallback(() => {
    if (!yDoc) return;

    //  const yMap = yDoc.getMap(type);

    const updateHandler = () => {
      setValue(yMap.get(type));

      if (timers[key]) clearTimeout(timers[key]);

      timers[key] = setTimeout(() => {
        timers[key] = null;

        store();

        setUpdated(true);
      }, 1000);
    };

    if (!yDocListeners[docId]) {
      yDocListeners[docId] = [];
    }

    yDocListeners[docId].push(updateHandler);
    /*yDoc.on('update', () => {
        
            const listeners = yDocListeners[docId]
            yDocListeners[docId] = []
            listeners.forEach((handler) => handler());
        });*/

    const observer = (v) => {
      return () => {
        setValue(yMap.get(type));

        if (timers[key]) clearTimeout(timers[key]);

        timers[key] = setTimeout(() => {
          timers[key] = null;

          store();

          setUpdated(true);
        }, 1000);
      };
    };
    const observerResult = observer(obs++);

    yMap.observe(observerResult);

    const value = yMap.get(type);
    setValue(value);

    return () => {
      yDocListeners[docId].splice(
        yDocListeners[docId].indexOf(updateHandler),
        1
      );

      if (yDocListeners[docId].length == 0) {
        // yDoc.off('update', updateHandler);
      }

      yMap.unobserve(observerResult);

      if (updated) store();
    };
  }, [loaded, yDoc?.guid, type, docId]);

  useEffect(stateUpdate, [loaded, yDoc?.guid, type, docId]);

  const setValueSynced = useCallback(
    (valueOrUpdater: React.SetStateAction<any>) => {
      if (!yDoc) return;

      if (stopOnMetadataChange) {
        // if(type == "metadata") debugger;
      }

      const freshValue = yMap.get(type);

      const newValue =
        typeof valueOrUpdater === 'function'
          ? valueOrUpdater(freshValue)
          : valueOrUpdater;

      if (type == 'nodes') {
        console.log(
          'setting nodes',
          (freshValue || []).length,
          '=>',
          (newValue || []).length
        );

        if (decr) {
          debugger;
          decr = false;
        }
        if ((freshValue || []).length > (newValue || []).length) {
          debugger;

          decr = true;
        }
      }

      // const yMap = yDoc.getMap(type);
      yDoc.transact(() => {
        yMap.set(type, newValue);
      }, userOrigin);
    },
    [value, yDoc]
  );

  // if(prevMetadata && prevMetadata !== value && type == "flow") debugger;

  if (type == 'flow') prevMetadata = value;
  return [value || defaultValue, setValueSynced, loaded, yDoc];
};

export default useSyncedDocument;
