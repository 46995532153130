// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';

import HomeIcon from '@mui/icons-material/Home';
import TaskIcon from '@mui/icons-material/Task';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TimelineIcon from '@mui/icons-material/Timeline';
import MapIcon from '@mui/icons-material/Map';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';

import { fetchUser } from '../collaboration/useYDoc';
import { useNavigate } from 'react-router-dom';

import useActiveUser from '../collaboration/useActiveUser';
import { Box, Select, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';

export enum CurrentPages {
  Home = 'home',
  Flows = 'flows',
  KeywordMaps = 'keyword-maps',
  Assistants = 'assistants',
  Vouchers = 'vouchers',
  History = 'history',
  Tasks = 'tasks',
  Customers = 'customers',
  Ledger = 'ledger',
  Reports = 'reports',
  Settings = 'settings',
  ChartOfAccounts = 'chart-of-accounts',
  BankAccounts = 'bank-accounts',
}

export type CurrentPage =
  | CurrentPages.Home
  | CurrentPages.Flows
  | CurrentPages.KeywordMaps
  | CurrentPages.Assistants
  | CurrentPages.Vouchers
  | CurrentPages.Customers
  | CurrentPages.History
  | CurrentPages.Tasks
  | CurrentPages.Reports
  | CurrentPages.ChartOfAccounts
  | CurrentPages.Settings
  | CurrentPages.BankAccounts;

export enum CurrentPageLabels {
  Home = 'Home',
  Flows = 'Flows',
  KeywordMaps = 'Contexts',
  Assistants = 'Assistants',
  Vouchers = 'Vouchers',
  History = 'History',
  Tasks = 'Tasks',
  Reports = 'Reports',
  Customers = 'Customers',
  Ledger = 'Ledger',
  Settings = 'Settings',
  ChartOfAccounts = 'Chart of Accounts',
  BankAccounts = 'Bank Accounts',
}

export type CurrentPageLabel =
  | CurrentPageLabels.Home
  | CurrentPageLabels.Flows
  | CurrentPageLabels.KeywordMaps
  | CurrentPageLabels.Assistants
  | CurrentPageLabels.Tasks
  | CurrentPageLabels.Reports
  | CurrentPageLabels.Customers
  | CurrentPageLabels.History
  | CurrentPageLabels.ChartOfAccounts
  | CurrentPageLabels.Settings
  | CurrentPageLabels.BankAccounts;

const headerBarStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  borderBottom: '0.1rem solid #d1dbe8',
  userSelect: 'none',
  gap: '5.5rem',
};

const flonessTitleStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '1.5rem',
  margin: '0 0 0 0.5rem',
  pointerEvents: 'none',
};

const headerLinksStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  flexGrow: 1,
  fontFamily: 'Inter',
  whiteSpace: 'nowrap',
};

const headerLinkStyle = {
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  padding: '8px',
  gap: '4px',
};

const headerLinkTextStyle = {
  fontSize: '0.75rem',
  margin: 0,
  display: 'none',
  '@media (min-width: 1400px)': {
    display: 'block',
  },
};

const headerLinkCurrentStyle = {
  color: '#4f7396',
};

const headerProfilePictureStyle = {
  marginRight: '0.5rem',
  cursor: 'pointer',
  border: '0.25rem solid transparent',
};

const headerProfilePictureHoverStyle = {
  border: '0.25rem solid #00000022',
};

const HeaderBar = (props: {
  currentPage: CurrentPage;
  setCurrentPage:
    | React.Dispatch<React.SetStateAction<CurrentPage>>
    | ((page: CurrentPage) => void);
}) => {
  const [pfpSrc, setPfpSrc] = useState<string | undefined>(undefined);
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const handleLanguageChange = (lang: string) => {
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const avatarRef = useRef(null);

  const navigate = useNavigate();

  const { activeUser } = useActiveUser();

  const [availableOrganisations, setAvailableOrganisations] = useState<
    string[]
  >([]);

  useEffect(() => {
    const fetchOrganisations = async () => {
      const response = await fetch('/api/organizations');
      setAvailableOrganisations(await response.json());
    };

    fetchOrganisations();
  }, [setAvailableOrganisations]);

  const [newOrganizationName, setNewOrganizationName] = useState<string>('');

  const createNewOrganisation = async (newOrganizationName) => {
    const response = await fetch('/api/organizations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ organizationName: newOrganizationName }),
    });

    const newOrganisation = await response.json();

    setAvailableOrganisations([...availableOrganisations, newOrganisation]);
  };

  useEffect(() => {
    setPfpSrc(activeUser.picture);
  }, []);

  const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation();

  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedbackTopic, setFeedbackTopic] = useState('');
  const [feedbackContent, setFeedbackContent] = useState('');
  const [feedbackRating, setFeedbackRating] = useState<number | null>(null);

  const handleFeedbackSubmit = () => {
    // Here you would typically send the feedback to your backend
    console.log({ feedbackTopic, feedbackContent, feedbackRating });
    setFeedbackDialogOpen(false);
    // Reset the form
    setFeedbackTopic('');
    setFeedbackContent('');
    setFeedbackRating(null);
  };

  const isFlonessUser = activeUser.email?.endsWith('@floness.com');

  return (
    <Box sx={headerBarStyle}>
      <h1 style={flonessTitleStyle}>Floness</h1>
      <div style={headerLinksStyle}>
        <p
          className={`headerLink${props.currentPage === CurrentPages.Home ? ' current' : ''}`}
          title={t(CurrentPageLabels.Home)}
          onClick={() => navigate('/dashboard')}
          style={
            props.currentPage === CurrentPages.Home
              ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
              : headerLinkStyle
          }
        >
          <HomeIcon />
          <Typography sx={headerLinkTextStyle}>
            {t(CurrentPageLabels.Home)}
          </Typography>
        </p>

        {isFlonessUser && (
          <>
            <p
              className={`headerLink${props.currentPage === CurrentPages.Tasks ? ' current' : ''}`}
              title={t(CurrentPageLabels.Tasks)}
              onClick={() => navigate('/tasks')}
              style={
                props.currentPage === CurrentPages.Tasks
                  ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
                  : headerLinkStyle
              }
            >
              <TaskIcon />
              <Typography sx={headerLinkTextStyle}>
                {t(CurrentPageLabels.Tasks)}
              </Typography>
            </p>
            <p
              className={`headerLink${props.currentPage === CurrentPages.Customers ? ' current' : ''}`}
              title={t(CurrentPageLabels.Customers)}
              onClick={() => navigate('/customers')}
              style={
                props.currentPage === CurrentPages.Customers
                  ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
                  : headerLinkStyle
              }
            >
              <PeopleIcon />
              <Typography sx={headerLinkTextStyle}>
                {t(CurrentPageLabels.Customers)}
              </Typography>
            </p>
            <p
              className={`headerLink${props.currentPage === CurrentPages.Ledger ? ' current' : ''}`}
              title={t(CurrentPageLabels.Ledger)}
              onClick={() => navigate('/ledger')}
              style={
                props.currentPage === CurrentPages.Ledger
                  ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
                  : headerLinkStyle
              }
            >
              <AccountBalanceIcon />
              <Typography sx={headerLinkTextStyle}>
                {t(CurrentPageLabels.Ledger)}
              </Typography>
            </p>
            <p
              className={`headerLink${props.currentPage === CurrentPages.BankAccounts ? ' current' : ''}`}
              title={t(CurrentPageLabels.BankAccounts)}
              onClick={() => navigate('/bank-accounts')}
              style={
                props.currentPage === CurrentPages.BankAccounts
                  ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
                  : headerLinkStyle
              }
            >
              <AccountBalanceIcon />
              <Typography sx={headerLinkTextStyle}>{t(CurrentPageLabels.BankAccounts)}</Typography>
            </p>
            <p
              className={`headerLink${props.currentPage === CurrentPages.ChartOfAccounts ? ' current' : ''}`}
              title={t(CurrentPageLabels.ChartOfAccounts)}
              onClick={() => navigate('/chart-of-accounts')}
              style={
                props.currentPage === CurrentPages.ChartOfAccounts
                  ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
                  : headerLinkStyle
              }
            >
              <AccountTreeIcon />
              <Typography sx={headerLinkTextStyle}>
                {t(CurrentPageLabels.ChartOfAccounts)}
              </Typography>
            </p>
            <p
              className={`headerLink${props.currentPage === CurrentPages.Reports ? ' current' : ''}`}
              title={t(CurrentPageLabels.Reports)}
              onClick={() => navigate('/reports')}
              style={
                props.currentPage === CurrentPages.Reports
                  ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
                  : headerLinkStyle
              }
            >
              <AssessmentIcon />
              <Typography sx={headerLinkTextStyle}>
                {t(CurrentPageLabels.Reports)}
              </Typography>
            </p>
          </>
        )}

        <p
          className={`headerLink${props.currentPage === CurrentPages.Flows ? ' current' : ''}`}
          title={t(CurrentPageLabels.Flows)}
          onClick={() => navigate('/dashboard/flows')}
          style={
            props.currentPage === CurrentPages.Flows
              ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
              : headerLinkStyle
          }
        >
          <TimelineIcon />
          <Typography sx={headerLinkTextStyle}>
            {t(CurrentPageLabels.Flows)}
          </Typography>
        </p>
        <p
          className={`headerLink${props.currentPage === CurrentPages.KeywordMaps ? ' current' : ''}`}
          title={t(CurrentPageLabels.KeywordMaps)}
          onClick={() => navigate('/dashboard/keyword-maps')}
          style={
            props.currentPage === CurrentPages.KeywordMaps
              ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
              : headerLinkStyle
          }
        >
          <MapIcon />
          <Typography sx={headerLinkTextStyle}>
            {t(CurrentPageLabels.KeywordMaps)}
          </Typography>
        </p>
        <p
          className={`headerLink${props.currentPage === CurrentPages.Assistants ? ' current' : ''}`}
          title={t(CurrentPageLabels.Assistants)}
          onClick={() => navigate('/dashboard/assistants')}
          style={
            props.currentPage === CurrentPages.Assistants
              ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
              : headerLinkStyle
          }
        >
          <SmartToyIcon />
          <Typography sx={headerLinkTextStyle}>
            {t(CurrentPageLabels.Assistants)}
          </Typography>
        </p>
        {true && (
          <p
            className={`headerLink${props.currentPage === CurrentPages.Assistants ? ' current' : ''}`}
            title={t('History')}
            onClick={() => navigate('/history')}
            style={
              props.currentPage === CurrentPages.History
                ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
                : headerLinkStyle
            }
          >
            <HistoryIcon />
            <Typography sx={headerLinkTextStyle}>{t('History')}</Typography>
          </p>
        )}
        <p
          className={`headerLink${props.currentPage === CurrentPages.Assistants ? ' current' : ''}`}
          title={'Organisation settings'}
          onClick={() => navigate('/settings')}
          style={
            props.currentPage === CurrentPages.Settings
              ? { ...headerLinkStyle, ...headerLinkCurrentStyle }
              : headerLinkStyle
          }
        >
          <SettingsIcon />
          <Typography sx={headerLinkTextStyle}>
            {t('Organisation settings')}
          </Typography>
        </p>
      </div>

      <IconButton
        onClick={() => setFeedbackDialogOpen(true)}
        sx={{ marginRight: '16px' }}
        title={t('Provide Feedback')}
      >
        <FeedbackIcon />
      </IconButton>

      <Dialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        aria-labelledby="feedback-dialog-title"
      >
        <DialogTitle id="feedback-dialog-title">
          {t('Provide Feedback')}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="feedback-topic"
            label={t('Topic')}
            type="text"
            fullWidth
            variant="outlined"
            value={feedbackTopic}
            onChange={(e) => setFeedbackTopic(e.target.value)}
          />
          <TextField
            margin="dense"
            id="feedback-content"
            label={t('Feedback')}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={feedbackContent}
            onChange={(e) => setFeedbackContent(e.target.value)}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography component="legend">{t('Rating')}</Typography>
            <Rating
              name="feedback-rating"
              value={feedbackRating}
              onChange={(event, newValue) => {
                setFeedbackRating(newValue);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackDialogOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              fetch('/api/feedback', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  topic: feedbackTopic,
                  content: feedbackContent,
                  rating: feedbackRating,
                }),
              })
                .then(() => {
                  setFeedbackDialogOpen(false);
                  // Optionally, you can add a success message or reset the form here
                })
                .catch((error) => {
                  console.error('Error submitting feedback:', error);
                  // Optionally, you can show an error message to the user here
                });
            }}
          >
            {t('Submit')}
          </Button>
        </DialogActions>
      </Dialog>

      <Select
        value={i18n.language}
        onChange={(event) => i18n.changeLanguage(event.target.value)}
        sx={{ width: '120px', marginRight: '16px' }}
      >
        <MenuItem value="fi">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="12"
            viewBox="0 0 20 12"
          >
            <rect width="20" height="12" fill="#FFFFFF" />
            <rect y="4" width="20" height="4" fill="#003580" />
            <rect x="7" width="2" height="12" fill="#003580" />
          </svg>{' '}
          Suomi
        </MenuItem>
        <MenuItem value="sv">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="12"
            viewBox="0 0 20 12"
          >
            <rect width="20" height="12" fill="#006AA7" />
            <rect x="6" width="2" height="12" fill="#FECC00" />
            <rect y="5" width="20" height="2" fill="#FECC00" />
          </svg>{' '}
          Svenska
        </MenuItem>
        <MenuItem value="en">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="12"
            viewBox="0 0 20 12"
          >
            <rect width="20" height="12" fill="#012169" />
            <path
              d="M0,0 L20,12 M20,0 L0,12"
              stroke="#FFFFFF"
              stroke-width="2"
            />
            <path
              d="M10,0 L10,12 M0,6 L20,6"
              stroke="#FFFFFF"
              stroke-width="3"
            />
            <path
              d="M10,0 L10,12 M0,6 L20,6"
              stroke="#C8102E"
              stroke-width="2"
            />
          </svg>{' '}
          English
        </MenuItem>
        <MenuItem value="da">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="12"
            viewBox="0 0 20 12"
          >
            <rect width="20" height="12" fill="#C8102E" />
            <rect x="6" width="2" height="12" fill="#FFFFFF" />
            <rect y="5" width="20" height="2" fill="#FFFFFF" />
          </svg>{' '}
          Dansk
        </MenuItem>
      </Select>

      <Select
        value={activeOrganisation?.organizationId}
        sx={{ width: '240px' }}
      >
        <MenuItem
          value={activeUser.id}
          onClick={() => {
            setActiveOrganisation({
              organizationId: activeUser.id,
              organizationName: `Personal account for ${activeUser.name}`,
            });
          }}
        >
          Personal account for {activeUser.name}
        </MenuItem>
        {availableOrganisations.map((organisation) => (
          <MenuItem
            onClick={() => {
              setActiveOrganisation(organisation);
            }}
            value={organisation.organizationId}
          >
            {organisation.organizationName || 'Unnamed organisation'}
          </MenuItem>
        ))}

        <TextField
          sx={{ width: 'calc(100% - 2rem)', margin: '1rem' }}
          value={newOrganizationName}
          onChange={(event) => {
            setNewOrganizationName(event.target.value);
          }}
          placeholder={'New organisation name'}
          inputProps={{
            onClick: (event) => event.stopPropagation(),
            onKeyDown: (event) => {
              event.stopPropagation();
              if (event.keyCode == 13) {
                setNewOrganizationName('');
                createNewOrganisation(newOrganizationName);
              }
            },
          }}
          onClick={(event) => event.stopPropagation()}
        ></TextField>
      </Select>
      <Avatar
        sx={headerProfilePictureStyle}
        src={pfpSrc}
        ref={avatarRef}
        onClick={() => setProfileMenuOpen(true)}
        onMouseOver={(e) =>
          (e.currentTarget.style.border = headerProfilePictureHoverStyle.border)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.border = headerProfilePictureStyle.border)
        }
      />
      <Menu
        open={profileMenuOpen}
        anchorEl={avatarRef.current}
        onClose={() => setProfileMenuOpen(false)}
      >
        <Typography variant="body2" sx={{ margin: '1rem' }}>
          {activeUser.name}
        </Typography>
        <Typography variant="body2" sx={{ margin: '1rem' }}>
          {activeUser.id}
        </Typography>
        <MenuItem
          onClick={() => {
            window.location.href = '/logout';
            // fetch('/logout').then((res) => console.log(res));
          }}
        >
          Sign out
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default HeaderBar;
